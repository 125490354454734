<template>
  <div id='calendar'>
    <!-- 年份 月份 -->
    <div class='month'>
      <ul>
        <!--点击会触发pickpre函数，重新刷新当前日期 @click(vue v-on:click缩写) -->
        <li class='arrow' @click='pickPre(currentYear,currentMonth)'>❮</li>
        <li class='year-month'>
          <span class='choose-year'>{{ currentYear }}年</span>
          <span class='choose-month'>{{ currentMonth }}月</span>
        </li>
        <li class='arrow' @click='pickNext(currentYear,currentMonth)'>❯</li>
      </ul>
    </div>
    <!-- 星期 -->
    <ul class='weekdays'>
      <li>一</li>
      <li>二</li>
      <li>三</li>
      <li>四</li>
      <li>五</li>
      <li>六</li>
      <li>日</li>
    </ul>
    <!-- 日期 -->
    <ul class='days'>
      <!-- 核心 v-for循环 每一次循环用<li>标签创建一天 -->
      <li v-for='(dayobject,i) in days' :key='i'>
        <!--本月-->
        <!--如果不是本月  改变类名加灰色-->
        <span v-if='dayobject.day.getMonth()+1 != currentMonth' class='other-month' @click="getDayTime(dayobject.day)">
          {{ dayobject.day.getDate() }}
          <div v-for="(item,index) in dayobject.moth_data" :key="index">
            <p :class="item.class"><span> {{item.message }}</span></p>
          </div>
        </span>
        <!--如果是本月  还需要判断是不是这一天-->
        <span v-else>
          <!--今天  同年同月同日 class='active'-->
          <span v-if='dayobject.day.getFullYear() == new Date().getFullYear() && dayobject.day.getMonth() == new Date().getMonth() && dayobject.day.getDate() == new Date().getDate()' @click="getDayTime(dayobject.day)">
            {{ dayobject.day.getDate() }}
            <div v-for="(item,index) in dayobject.moth_data" :key="index">
              <p :class="item.class"><span> {{item.message }}</span></p>
            </div>
          </span>
          <span v-else @click="getDayTime(dayobject.day)">
            {{ dayobject.day.getDate() }}
            <div v-for="(item,index) in dayobject.moth_data" :key="index">
              <p :class="item.class"><span> {{item.message }}</span></p>
            </div>
          </span>
        </span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentDay: 1,
      currentMonth: 1,
      currentYear: 1970,
      currentWeek: 1,
      currentallDay: 30,
      days: [],
      moth_data: [{ message: "1", class: 'daysList-item' }, { message: "2", class: 'daysList-item' }, { message: "3", class: 'daysList-item' }],
    };
  },
  created: function () {
    // 在vue初始化时调用
    this.initData(null);
  },
  methods: {
    initData: function (cur) {
      // var leftcount = 0 // 存放剩余数量
      var date;
      if (cur) {
        date = new Date(cur);
      } else {
        var now = new Date();
        var dd = new Date(this.formatDate(now.getFullYear(), now.getMonth(), 1));
        dd.setDate(35);
        date = new Date(this.formatDate(dd.getFullYear(), dd.getMonth() + 1, 1));
      }

      this.currentDay = date.getDate();
      this.currentYear = date.getFullYear();
      this.currentMonth = date.getMonth() + 1;
      this.currentWeek = date.getDay() - 1; // 1...6,0
      var d = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.currentallDay = d.getDate();
      if (this.currentWeek === 0) {
        //this.currentWeek = 7;
      }
      var str = this.formatDate(
        this.currentYear,
        this.currentMonth,
        this.currentDay
      );

      this.days.length = 0;
      // 今天是周日，放在第一行第7个位置，前面6个
      // 初始化本周

      for (var i = this.currentWeek; i >= 0; i--) {
        var d2 = new Date(str);
        d2.setDate(d2.getDate() - i);
        var dayobjectSelf = {}; // 用一个对象包装Date对象  以便为以后预定功能添加属性
        dayobjectSelf.day = d2;
        //查询这个月对应的每一天的数据
        dayobjectSelf.moth_data = this.moth_data;//this.moth_data.filter(a=>a.methods==d2)
        this.days.push(dayobjectSelf); // 将日期放入data 中的days数组 供页面渲染使用
      }
      // 其他周
      //this.currentallDay; 35 - this.currentWeek
      for (var j = 1; j <= this.currentallDay - 1; j++) {
        var d3 = new Date(str);
        d3.setDate(d3.getDate() + j);
        var dayobjectOther = {};
        dayobjectOther.day = d3;
        //查询这个月对应的每一天的数据
        dayobjectOther.moth_data = this.moth_data;//this.moth_data.filter(a=>a.methods==d2)
        this.days.push(dayobjectOther);
      }
      console.log(this.days);

    },
    getDayTime(el) {
      alert(el);
    },
    pickPre: function (year, month) {
      // setDate(0); 上月最后一天
      // setDate(-1); 上月倒数第二天
      // setDate(dx) 参数dx为 上月最后一天的前后dx天
      var d = new Date(this.formatDate(year, month, 1));
      d.setDate(0);
      this.initData(this.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
    },
    pickNext: function (year, month) {
      var d = new Date(this.formatDate(year, month, 1));
      d.setDate(35);
      this.initData(this.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
    },
    // 返回 类似 2016-01-02 格式的字符串
    formatDate: function (year, month, day) {
      var y = year;
      var m = month;
      if (m < 10) m = "0" + m;
      var d = day;
      if (d < 10) d = "0" + d;
      return y + "-" + m + "-" + d;
    },
  },
};
</script>
<style scoped>
#calendar {
  font-size: 12px;
  width: 100%;
  margin: 0 auto;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.1),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.month {
  width: 100%;
  color: #333333;
}
.month ul {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  height: 35px;
}
.year-month {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
}
.choose-month {
  text-align: center;
  font-size: 12px;
}
.arrow {
  padding: 15px;
  color: #999999;
}

.month ul li {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 3px;
}
.weekdays {
  margin: 0;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  color: #999;
  justify-content: space-around;
}
.weekdays li {
  display: inline-block;
  width: 13.6%;
  text-align: center;
}
.days {
  padding: 10px;
  background: #ffffff;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
.days li {
  list-style-type: none;
  display: inline-block;
  width: 14.2%;
  text-align: center;
  padding-bottom: 4px;
  padding-top: 10px;
  font-size: 12px;
  color: #000;
  /* border-right: 1px solid #faf2f2; */
  border-bottom: 1px solid #faf2f2;
}

.days li:nth-child(1),
.days li:nth-child(2),
.days li:nth-child(3),
.days li:nth-child(4),
.days li:nth-child(5),
.days li:nth-child(6),
.days li:nth-child(7) {
  border-top: 1px solid #faf2f2;
  border-left: 1px solid #faf2f2;
}
.days li:nth-child(n + 7) {
  border-left: 1px solid #faf2f2;
}
.days li:nth-child(7),
.days li:nth-child(14),
.days li:nth-child(21),
.days li:nth-child(28),
.days li:nth-child(35) {
  border-right: 1px solid #faf2f2;
}
.days li:last-child {
  border-right: 1px solid #faf2f2;
}
.days li .active {
  padding: 6px 10px;
  border-radius: 50%;
  background: #00b8ec;
  color: #fff;
}
.days li .other-month {
  padding: 5px;
  color: gainsboro;
}
.days li:hover > span > span {
  padding: 6px 10px;
  border-radius: 50%;
  background: #e1e1e1;
  color: #fff;
}
.daysList-item {
  font-size: 6px;
  transform: scale(0.5);
  height: 10px;
}
.daysList-item span:before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 4px;
  background-color: #666;
  vertical-align: middle;
  margin-right: 7px;
  border-radius: 4px;
}
</style>